// Write your custom css/scss here
$primary-pink:      #BC47A4;
$primary-blue:      #3A8CA9;

.airnet-btn {
    background: transparent;
    border: 1px solid $primary-pink;
    border-radius: 100px;
    padding: 5px 15px;
}

.airnet-btn a {
    color: $primary-pink;
}

.list-group {
    overflow: hidden;
}

.dashboard-body-message .show {
    background: #eee;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    overflow: auto;
}

.close-body-message {
    display: flex;
    justify-content: end;
    padding: 5px;
    z-index: 10;
    padding-right: 50px;
    cursor: pointer;
    position: relative;
    top: 10px;
}

.airnet-btn-blue {
    background: transparent;
    border: 1px solid $primary-blue;
    border-radius: 100px;
    color: $primary-blue;
    padding: 5px 15px;
}

.airnet-btn-blue a {
    color: $primary-blue;
}

.custom-list-item {
    padding: 18px 1.25rem;
}

.custom-card-padding {
    padding: 10px 0px;
}

.right-icon {
    width: 15px;
    height: 15px;
}

.custom-search-input {
    border: 1px solid #f2f2f2;
    border-radius: 100px;
    padding: 8px 20px;
}

.custom-search-input:focus {
    border: 1px solid #f2f2f2;
}

.new {
    background-color: #F2F2F2;
}

.custom-sortby,
.custom-sortby:hover,
.custom-sortby:focus
{
    border: 1px solid #BDBDBD;
    border-color: #BDBDBD !important;
    box-shadow: none !important;
    border-radius: 100px;
    color: #4F4F4F !important;
    background-color: transparent !important;
}


.form-check .form-check-label input[type=checkbox] + .input-frame:before {
    border-color: #E0E0E0;
}
.form-check .form-check-label input[type=checkbox]:checked + .input-frame:after {
    background: $primary-pink;
}

.message-body {
    padding: 20px 40px;
}

.login-page {
    background: url('/images/login-bg-image.png');
}

.login-page .card {
    background: rgba(239, 233, 246, 0.2);
    box-shadow: 0px 0px 32px rgb(17 18 21 / 7%);
    backdrop-filter: blur(50px);
    border-radius: 8px;
    padding: 100px 0px;
}

.login-btn {
    background: linear-gradient(144.08deg, #EF88DA 0%, #BC47A4 100%);
    border-radius: 32px;
    color: #fff;
    border: none;
    padding: 15px 50px;
    margin: 50px 0px;
}

.custom-text-gradient {
    font-weight: 700;
    font-size: 16px !important;
}

li.active .custom-text-gradient,
a.true span.link-title
{
    background: linear-gradient(128.76deg, #53C8F1 11.44%, #9FC4FB 38.81%, #D1ADF5 65.84%, #EF88DA 92.28%);
    background-clip: text !important;
    -webkit-background-clip: text !important;
    color: transparent !important;
}

.package-title {
    color: $primary-pink;
    font-weight: 800;
    font-size: 30px;
}

.change-package {
    background: transparent;
    border: 1px solid #3A8CA9;
    border-radius: 30px;
    color: #828282;
    padding: 15px 45px;
}

.download-ugovor {
    color: #4F4F4F;
    font-weight: 700;
    text-decoration: underline;
}


.invoice-body {
    padding: 20px 10px;
}

.invoice-body p {
    margin-bottom: 20px;
}


.list-group-item {
    position: unset;
}


.login-page #exampleInputEmail1,
.login-page #exampleInputPassword1,

.login-page #exampleInputEmail1:active,
.login-page #exampleInputPassword1:active,

.login-page #exampleInputEmail1:focus,
.login-page #exampleInputPassword1:focus,

.login-page #exampleInputEmail1:-webkit-autofill,
.login-page #exampleInputPassword1:-webkit-autofill
{
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #BDBDBD;
    backdrop-filter: blur(50px);
    border-radius: 8px;
    height: 40px;
}

.login-page #exampleInputEmail1::placeholder,
.login-page #exampleInputPassword1::placeholder
{ /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #333;
    opacity: 1; /* Firefox */
}

.login-page #exampleInputEmail1:-ms-input-placeholder,
.login-page #exampleInputPassword1:-ms-input-placeholder 
{ /* Internet Explorer 10-11 */
    color: #333;
}

.login-page #exampleInputEmail1::-ms-input-placeholder,
.login-page #exampleInputPassword1::-ms-input-placeholder
{ /* Microsoft Edge */
    color: #333;
}

@media(max-width: 700px){
    .login-page form {
        padding: 10px !important;
    }
}


/********************* Select2 **************************/
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    color: #fff;
}



/* LOADER */
.loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: #87878782;
    z-index: 99999;
}

.loading_none {
    display: none;
}



/*
DASHBOARD
*/

@media(max-width: 500px){
    .contract {
        margin-top: -1rem !important;
    }

    .message .img ,
    .contract .img
    {
        width: 20px;
    }

    .message button > a, 
    .contract button > a
    {
        font-size: 12px !important;
    }

    .message h5,
    .message strong,
    .contract h5,
    .contract strong
    {
        font-size: 13px;
    }
}

td.details-control:before {
    height: 1em;
    width: 1em;
    margin-top: -9px;
    display: inline-block;
    color: white;
    border: 0.15em solid white;
    border-radius: 1em;
    box-shadow: 0 0 0.2em #444;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: "Courier New",Courier,monospace;
    line-height: 1em;
    content: "+";
    background-color: #31b131;
}

tr.shown td.details-control:before {
    content: "-";
    background-color: #d33333;
}



.hide {
    display: none;
}

@media(max-width: 500px){
    .mobile_message {
        flex-direction: column;
        align-items: flex-start !important;
    }

    .mobile_message .search-form,
    .mobile_message .search-form input
    {
        width: 100%;
    }

    .mobile_message_header {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 15px;
    }

    #users-table_filter,
    #usersRequirement-table_filter,
    #internet-table_filter
    {
        margin-left: 0px;
    }

    .accept {
        width: 100% !important;
    }

    .change-package {
        padding: 10px 35px;
        width: 100%;
    }

    .bottom-contract {
        gap: 32px;
        margin: 16px;
    }

    .bottom-contract > div > div {
        justify-content: center;
    }

    #profileDropdown > span {
        display: none;
    }

    .dataTables_length select {
        max-width: 30% !important;
    }

    .dataTables_paginate > .pagination {
        margin-bottom: 2rem !important;
    }
}